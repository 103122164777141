<template>
  <el-dialog 
    :title="itemId ? '修改':'新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="userName" label="姓名" verify>
          <el-input v-model="formData.userName"></el-input>
        </el-form-item>
        <el-form-item prop="account" label="账号名称" verify>
          <el-input v-model="formData.account"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" verify>
          <el-input v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item prop="userType" label="权限组" verify>
          <gl-option v-model="formData.userType" :list="authList"></gl-option>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  name: 'account-update',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      authList: [
        {name: '管理员', value: 'admin'},
        {name: '用户', value: 'user'}
      ]
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getDetail()
      }
    },
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async(valid) => {
        if(valid){
          console.log('submit-formData:', this.formData)
          this.loading = true
          setTimeout(() => {
            this.loading = false
            this.handleClose()
          },1000)
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
    getDetail(){
      let params = {
        itemId: this.itemId,
      }
      this.loading = true
      setTimeout(() => {
        this.formData = {
          userName: 'userName',
          account: '我是账号'
        }
        this.loading = false
      }, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$nextTick(() => this.$refs.form.resetFields() )  //重置表单
    },
  },
}
</script>

<style>

</style>