<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form :model="formData" ref="form" label-width="100px" size="mini" v-loading="loading">
      <el-form-item label="昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item label="账号名称">
        <div> {{formData.account}} </div>
      </el-form-item>
      <el-form-item label="密码">
        <div> {{formData.password}} </div>
      </el-form-item>
      <el-form-item label="手机号">
        <div> {{formData.phone}} </div>
      </el-form-item>
      <el-form-item label="权限组">
        <div> {{formData.authorization}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >取消</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      loading: false,
      formData: {},
      authList: [
        {name: '管理员', value: 'admin'},
        {name: '用户', value: 'user'}
      ]
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getDetail()
      }
    },
  },
  methods: {
    getDetail(itemId){
      let params = {
        itemId: this.itemId
      }
      this.loading = true
      setTimeout(() => {
        this.formData = {
          account: '账号'
        }
        this.loading = false
      }, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
    },
  },
}
</script>

<style>

</style>