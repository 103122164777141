export default {
  data(){
    return{
      mLoading: false,
      mFormData: {},
      mForm: {},  //在页面定义参数
      mParams: {
        pageNum: 1,
        pageSize: 10,
      },
      mTableData: [
        // {
        //   id: 'id888888888',
        //   title: '我是标题',
        //   phone: '5555555555',
        //   specification: '公仔',
        //   consignee: 'evan',
        //   paymentMethod: 1,

        //   userName: '彭虎',
        //   account: 'evan',
        //   userType: "admin",

        //   authName: 'admin',
        //   authPerson: 'authPerson'
        // },
        // {
        //   id: 'id222222222',
        //   title: '我是标题',
        //   phone: '18666666666',
        //   specification: '公仔3',
        //   consignee: 'evan3',
        //   paymentMethod: 2,
        //   content: '测试文字测试文字测试文字测试文字测试文字测试文字测试文字测试文字',

        //   userName: '彭虎2',
        //   account: 'evan-3',
        //   userType: "user",

        //   authName: 'user',
        //   authPerson: 'authPerson'
        // }
      ],
      mTotal: 0,
      mPageNum: 0,
      //方法
      // mGetListFun: (time = 500) => new Promise(resolve => setTimeout(resolve, time )),
      mGetListFun: null,
      mDeleteFun: null,
      mPublishFun: null,
      mRevokeFun: null,
      mDateRef: 'dateRef',
      //弹窗
      mItemId: null,
      mUpdateVisible: false,
      mPreviewVisible: false,
    }
  },
  mounted(){
    this.mGetListFun && this.mGetList()
  },
  methods: {
    //默认获取列表方法
    async mGetList(){
      this.mLoading = true
      const params = {
        ...this.mForm,
        ...this.mParams,
        ...this.mFormData,
      }
      console.log('params:', params)
      try{
        const res = await this.mGetListFun(params)
        this.mLoading = false
        this.mTableData = res.data.rows
        this.mTotal = Number(res.data.total)
        this.mPageNum = res.data.pageNum
      }catch(e){
        this.mLoading = false
      }
      
    },
    mConfirm(tip = '确认继续操作？', title = '提示', type = 'warning'){
      return this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: type,
      }).then(res => {
        return 'confirm'
      }).catch(err => {
        return Promise.reject('cancel')
      })
    },
    //删除数据
    async mDelete(id, tip = '此操作将永久删除该条数据, 是否继续?'){
      try{
        await this.mConfirm(tip)
        this.mLoading = true
        const res = await this.mDeleteFun(id)
        this.mLoading = false
        this.$message.success('操作成功')
        console.error('执行查询列表')
        this.mGetList()
      }catch(e){
        this.mLoading = false
      }
    },
    //发布数据
    async mPublish(id){
      try{
        let confirmType = ''  // success
        const confirm = await this.mConfirm('是否确认此操作？', '提示', confirmType)
        this.mLoading = true
        const res = await this.mPublishFun(id)
        this.mLoading = false
        this.$message.success('操作成功')
        this.mGetList()
      }catch(e){
        this.mLoading = false
      }
    },
    //下架数据
    async mRevoke(id){
      try{
        let confirmType = ''  // success
        const confirm = await this.mConfirm('是否确认此操作？', '提示', confirmType)
        this.mLoading = true
        const res = await this.mRevokeFun(id)
        this.mLoading = false
        this.$message.success('操作成功')
        this.mGetList()
      }catch(e){
        this.mLoading = false
      }
    },
    // 改变页码
    mHandleCurrentChange(pageNum){
      this.mParams.pageNum = pageNum
      this.mGetList()
    },
    // 改变每页数据条数
    mHandleSizeChange(size){
      this.mParams.pageSize = size
      this.mGetList()
    },
    mReload(){
      this.mParams.pageNum = 1
      this.mGetList()
    },
    mIndexMethod(index) {
      return (
        this.mParams.pageSize * (this.mParams.pageNum - 1) + index + 1
      )
    },
    mResetForm(fromName){
      this.$refs[fromName].resetFields()
      this.$data.mFormData = this.$options.data().mFormData
      // Object.assign(this.$data, this.$options.data())
      if(this.$refs[this.mDateRef]) this.$refs[this.mDateRef].reset()
    },
    //按钮操作
    mClickAction(name, itemId){
      this.mItemId = itemId || null
      this[name] = true
    },
  },
    
}